/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { IAnalyticsProfile } from 'generated-types';
import { AnalyticsProfile } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    ip,
    ipv6,
} from 'ng/shared/utils/regex.utils';

import { parseServerWithPort } from 'ng/shared/utils/ip-parser.utils';

import {
    analyticsProfileAnalyticsTypeLabelHash,
    IExtendedAnalyticsProfile,
    IServerConfig,
} from './analytics-profile.item.types';

import { SensitiveLogProfileConfigItem } from './sensitive-log-profile.config-item.factory';
import * as l10n from './analytics-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAnalyticsProfilePartial = Omit<IAnalyticsProfile, 'sensitive_log_profile'>;

interface IAnalyticsProfileConfig extends TAnalyticsProfilePartial {
    sensitive_log_profile: SensitiveLogProfileConfigItem,
}

interface IAnalyticsProfileData {
    config: IAnalyticsProfileConfig
}

/**
 * @description Analytics profile Item class for setting up Backup Scheduler.
 * @author Ratan Kumar
 */
export class AnalyticsProfileItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IAnalyticsProfileData;

    /**
     * Set True if 'client_log_streaming_config' Has property  'external_server'.
     */
    public useExternalLogs: boolean;

    /**
     * Will Hold Sensitive Log Profile Config item type.
     */
    public SensitiveLogProfileConfig: SensitiveLogProfileConfigItem;

    public externalServerConfigs: IServerConfig[];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'analyticsprofile',
            objectType: AnalyticsProfile,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);
        this.useExternalLogs = false;

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Get log streaming external server config object.
     */
    private static getDefaultExternalLogServerConfig(): IServerConfig {
        return {} as any as IServerConfig;
    }

    /**
     * Reduce comma separated string to list of server config objects.
     * @param externalServerWithPort - IP address with port in the string format.
     *      eg: ip: 127.0.0.1:80 or ipv6: [2001:db8:85a3::8a2e:370:7334]:516
     */
    private static externalLogServerParserReducer(
            acc: IServerConfig[],
            externalServerWithPort: string,
    ): IServerConfig[] {
        const externalServerObject = AnalyticsProfileItem
            .getDefaultExternalLogServerConfig();

        const [server, port] = parseServerWithPort(externalServerWithPort);

        if (port) {
            externalServerObject.port = Number(port);
        }

        externalServerObject.server = server;
        acc.push(externalServerObject);

        return acc;
    }

    /**
     * Reduce external log server config to list of string.
     */
    private static externalLogServerFormatterReducer(
        acc: string[],
        { server, port }: IServerConfig,
    ): string[] {
        let serverStr = '';
        const portValue = Number(port);

        if (ipv6.test(server)) {
            serverStr = portValue ? `[${server}]:${portValue}` : `[${server}]`;
        } else if (ip.test(server)) {
            serverStr = portValue ? `${server}:${portValue}` : server;
        } else {
            return acc;
        }

        acc.push(serverStr);

        return acc;
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();
        const { client_log_streaming_config: clientLogStreaming } = config;
        const clientLogStreamingField = 'client_log_streaming_config';

        if (clientLogStreaming?.config.external_server) {
            this.useExternalLogs = true;
        } else {
            this.useExternalLogs = false;

            this.safeSetNewChildByField(clientLogStreamingField);
        }

        this.externalServerConfigs = [];

        const { client_log_streaming_config: { config: clientLogStreamingConfig } } = config;

        if ('external_server' in clientLogStreamingConfig) {
            const externalServerWithPortConfig =
                clientLogStreamingConfig.external_server.split(',');

            this.externalServerConfigs = externalServerWithPortConfig
                .reduce(AnalyticsProfileItem.externalLogServerParserReducer, []);
        } else {
            this.externalServerConfigs
                .push(AnalyticsProfileItem.getDefaultExternalLogServerConfig());
        }

        config.analyticsEnabledFor = [];

        const analyticsPropertyList = Object.keys(analyticsProfileAnalyticsTypeLabelHash);

        config.analyticsEnabledFor = analyticsPropertyList.filter(key => config[key]);
    }

    /** @override */
    public dataToSave(): IExtendedAnalyticsProfile {
        const config = super.dataToSave();
        const { client_log_streaming_config: strConfig } = config;

        if (this.externalServerConfigs.length) {
            const serverConfigs = this.externalServerConfigs
                .reduce(AnalyticsProfileItem.externalLogServerFormatterReducer, []);

            strConfig.external_server = serverConfigs.join(',');
        }

        if (!this.useExternalLogs) {
            delete config.client_log_streaming_config;
        }

        const {
            sensitive_log_profile: {
                header_field_rules: headerFieldRules,
                waf_field_rules: wafFieldRules,
                uri_query_field_rules: uriQueryFieldRules,
            },
        } = config;

        if (!headerFieldRules && !wafFieldRules && !uriQueryFieldRules) {
            delete config.sensitive_log_profile;
        }

        if (!config.exclude_http_error_codes?.length) {
            delete config.exclude_http_error_codes;
        }

        const { analyticsEnabledFor } = config;
        const analyticsPropertyList = Object.keys(analyticsProfileAnalyticsTypeLabelHash);

        if (analyticsEnabledFor && analyticsEnabledFor.length) {
            analyticsPropertyList.forEach(key => {
                config[key] = analyticsEnabledFor.includes(key);
            });
        } else {
            analyticsPropertyList.forEach(value => config[value] = false);
        }

        delete config.analyticsEnabledFor;

        return config;
    }

    /**
     * Sets "exclude_http_error_codes", "ranges", and "resp_code_block" properties on config.
     */
    public handleRespCodesUpdate(respCodes: IAnalyticsProfile): void {
        this.config.exclude_http_error_codes = respCodes.exclude_http_error_codes;
        this.config.ranges?.updateConfig(respCodes.ranges);
        this.config.resp_code_block?.updateConfig(respCodes.resp_code_block);
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            AnalyticsProfileModalComponent,
        } = await import(
            /* webpackChunkName: "analytics-profile-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/analytics-profile-modal/analytics-profile-modal.component'
        );

        return AnalyticsProfileModalComponent as Type<Component>;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.analyticsProfileModalBreadcrumbTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'sensitive_log_profile',
        ];
    }
}
