/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    ApplicationProfile,
    ApplicationProfileCollection,
    CompressionFilterConfigItem,
    CompressionProfileConfigItem,
    DnsServiceApplicationProfileConfigItem,
    DosRateLimitProfileConfigItem,
    HTTPApplicationProfileConfigItem,
    HttpCacheConfigConfigItem,
    HTTPRedirectActionConfigItem,
    RateLimiterActionConfigItem,
    RateLimiterProfileConfigItem,
} from '.';

import {
    APPLICATION_PROFILE_COLLECTION_TOKEN,
    APPLICATION_PROFILE_ITEM_TOKEN,
    COMPRESSION_FILTER_CONFIG_ITEM,
    COMPRESSION_PROFILE_CONFIG_ITEM,
    DNS_SERVICE_APPLICATION_PROFILE_CONFIG_ITEM,
    DOS_RATE_LIMIT_PROFILE_CONFIG_ITEM,
    HTTP_APPLICATION_PROFILE_CONFIG_ITEM,
    HTTP_CACHE_CONFIG_CONFIG_ITEM,
    HTTP_REDIRECT_ACTION_CONFIG_ITEM,
    RATE_LIMITER_ACTION_CONFIG_ITEM,
    RATE_LIMITER_PROFILE_CONFIG_ITEM,
} from './application-profile.tokens';

const ApplicationProfileModule = angular.module('avi/application-profile');

const factories = [
    {
        factory: ApplicationProfile,
        name: APPLICATION_PROFILE_ITEM_TOKEN,
    },
    {
        factory: ApplicationProfileCollection,
        name: APPLICATION_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: DosRateLimitProfileConfigItem,
        name: DOS_RATE_LIMIT_PROFILE_CONFIG_ITEM,
    },
    {
        factory: RateLimiterProfileConfigItem,
        name: RATE_LIMITER_PROFILE_CONFIG_ITEM,
    },
    {
        factory: DnsServiceApplicationProfileConfigItem,
        name: DNS_SERVICE_APPLICATION_PROFILE_CONFIG_ITEM,
    },
    {
        factory: HTTPApplicationProfileConfigItem,
        name: HTTP_APPLICATION_PROFILE_CONFIG_ITEM,
    },
    {
        factory: CompressionProfileConfigItem,
        name: COMPRESSION_PROFILE_CONFIG_ITEM,
    },
    {
        factory: CompressionFilterConfigItem,
        name: COMPRESSION_FILTER_CONFIG_ITEM,
    },
    {
        factory: HttpCacheConfigConfigItem,
        name: HTTP_CACHE_CONFIG_CONFIG_ITEM,
    },
    {
        factory: RateLimiterActionConfigItem,
        name: RATE_LIMITER_ACTION_CONFIG_ITEM,
    },
    {
        factory: HTTPRedirectActionConfigItem,
        name: HTTP_REDIRECT_ACTION_CONFIG_ITEM,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(ApplicationProfileModule, 'factory', name, factory);
});
