/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { copy } from 'angular';

import {
    AuthProfileType,
    AviPermissionResource,
    IAuthProfile,
    IAuthProfileHTTPClientParams,
    IAuthTacacsPlusAttributeValuePair,
    ILdapAuthSettings,
    ILdapDirectorySettings,
    ILdapUserBindSettings,
    ITacacsPlusAuthSettings,
} from 'generated-types';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { AuthProfile } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { Component, Type } from '@angular/core';
import { SamlSettingsConfigItem } from './saml-settings.config-item.factory';
import * as l10n from './auth-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAuthProfilePartial = Omit<
IAuthProfile, 'saml' | 'tacacs_plus' | 'ldap' | 'http' | 'oauth_profile'
>;
type TAuthProfileTacacsPlusPartial = Omit<ITacacsPlusAuthSettings, 'authorization_attrs'>;
type TAuthProfileLdapPartial = Omit<ILdapAuthSettings, 'settings' | 'user_bind'>;

export interface IAuthProfileTacacsPlusConfig extends TAuthProfileTacacsPlusPartial {
    authorization_attrs?: RepeatedMessageItem<MessageItem<IAuthTacacsPlusAttributeValuePair>>,
}

export interface IAuthProfileLdapConfig extends TAuthProfileLdapPartial {
    settings?: MessageItem<ILdapDirectorySettings>,
    user_bind?: MessageItem<ILdapUserBindSettings>,
}

export interface IAuthProfileConfig extends TAuthProfilePartial {
    saml?: SamlSettingsConfigItem,
    tacacs_plus?: MessageItem<IAuthProfileTacacsPlusConfig>,
    ldap?: MessageItem<IAuthProfileLdapConfig>,
    http?: MessageItem<IAuthProfileHTTPClientParams>,
    oauth_profile?: MessageItem<IAuthProfileHTTPClientParams>,
}

interface IAuthProfileData {
    config: IAuthProfileConfig,
}

const OBJECT_NAME = 'authprofile';

/**
 * @description Auth Profile Item.
 *
 * @author Rajawant Prajapati, Suraj Kumar
 */
export class AuthProfileItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'defaultValues',
        'l10nService',
    ];

    public data: IAuthProfileData;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: OBJECT_NAME,
            objectType: AuthProfile,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_AUTHPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Sets type and default sub-config values when user switches Auth Profile types.
     * Available on creation only.
     */
    public setType(selectedAuthProfileType: AuthProfileType): void {
        const config = this.getConfig();

        config.type = selectedAuthProfileType;

        this.syncSubObjectsWithType();
    }

    /**
     * Returns Auth Profile type.
     * AuthProfileType protobuf enum. Returns empty string if not set.
     */
    public getType(): AuthProfileType | '' {
        const config = this.getConfig();

        return config.type;
    }

    /** @override */
    public dataToSave(): IAuthProfile {
        const config = copy(super.dataToSave());

        return config;
    }

    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            AuthProfileModalComponent,
        } = await import(
            /* webpackChunkName: "auth-profile-modal" */
            'ng/lazy-loaded-components/modals/auth-profile-modal/auth-profile-modal.component'
        );

        return AuthProfileModalComponent as Type<Component>;
    }

    /**
     * Method for creating field if doesn't exist in the configuration.
     */
    public createSafeSetNewChildByField(fieldName: string): void {
        this.safeSetNewChildByField(fieldName);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.authProfileModalBreadcrumbTitle);
    }

    /**
     * Synchronizes auth profile type with corresponding configuration sub objects.
     */
    private syncSubObjectsWithType(): void {
        const config = this.getConfig();
        const { type } = config;
        const propsToDrop = [
            'tacacs_plus',
            'saml',
            'pa_agent_ref',
            'jwt_profile_ref',
            'ldap',
            'http',
            'oauth_profile',
        ];

        propsToDrop.forEach(propName => delete config[propName]);

        switch (type) {
            case AuthProfileType.AUTH_PROFILE_LDAP:
                this.setNewChildByField('ldap');
                this.setNewChildByField('http');

                break;

            case AuthProfileType.AUTH_PROFILE_TACACS_PLUS:
                this.setNewChildByField('tacacs_plus');

                break;

            case AuthProfileType.AUTH_PROFILE_SAML:
                this.setNewChildByField('saml');

                break;

            case AuthProfileType.AUTH_PROFILE_OAUTH:
                this.setNewChildByField('oauth_profile');

                break;

            case AuthProfileType.AUTH_PROFILE_PINGACCESS:
                config.pa_agent_ref = undefined;

                break;

            case AuthProfileType.AUTH_PROFILE_JWT:
                config.jwt_profile_ref = undefined;

                break;
        }
    }
}
