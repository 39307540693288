/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Nisar Nadaf
 */

/**
 * Ajs dependency token for ApplicationProfile item.
 */
export const APPLICATION_PROFILE_ITEM_TOKEN = 'ApplicationProfile';

/**
 * Ajs dependency token for ApplicationProfile Collection.
 */
export const APPLICATION_PROFILE_COLLECTION_TOKEN = 'ApplicationProfileCollection';

/**
 * Ajs dependency token for DosRateLimitProfile Config Item.
 */
export const DOS_RATE_LIMIT_PROFILE_CONFIG_ITEM = 'DosRateLimitProfileConfigItem';

/**
 * Ajs dependency token for RateLimiterProfile Config Item.
 */
export const RATE_LIMITER_PROFILE_CONFIG_ITEM = 'RateLimiterProfileConfigItem';

/**
 * Ajs dependency token for DnsServiceApplicationProfile Config Item.
 */
export const DNS_SERVICE_APPLICATION_PROFILE_CONFIG_ITEM = 'DnsServiceApplicationProfileConfigItem';

/**
 * Ajs dependency token for HTTPApplicationProfile Config Item.
 */
export const HTTP_APPLICATION_PROFILE_CONFIG_ITEM = 'HTTPApplicationProfileConfigItem';

/**
 * Ajs dependency token for CompressionProfile Config Item.
 */
export const COMPRESSION_PROFILE_CONFIG_ITEM = 'CompressionProfileConfigItem';

/**
 * Ajs dependency token for CompressionFilter Config Item.
 */
export const COMPRESSION_FILTER_CONFIG_ITEM = 'CompressionFilterConfigItem';

/**
 * Ajs dependency token for HttpCacheConfig Config Item.
 */
export const HTTP_CACHE_CONFIG_CONFIG_ITEM = 'HttpCacheConfigConfigItem';

/**
 * Ajs dependency token for RateLimiterAction Config Item.
 */
export const RATE_LIMITER_ACTION_CONFIG_ITEM = 'RateLimiterActionConfigItem';

/**
 * Ajs dependency token for HTTPRedirectAction Config Item.
 */
export const HTTP_REDIRECT_ACTION_CONFIG_ITEM = 'HTTPRedirectActionConfigItem';
