/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Type,
} from '@angular/core';
import { isUndefined } from 'underscore';
import {
    Auth,
    AUTH_SERVICE_TOKEN,
} from 'ajs/modules/core/services/auth/auth.service';
import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import {
    AviPermissionResource,
    IBfdProfile,
    IVrfContext,
} from 'generated-types';
import { VrfContext } from 'object-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { L10nService } from '@vmw/ngx-vip';
import { BgpProfileConfigItem } from './bgp-profile.config-item.factory';
import { StaticRouteConfigItem } from './static-route.config-item.factory';
import { GatewayMonitorConfigItem } from './gateway-monitor.config-item.factory';
import * as l10n from '../vrf-context.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   VRF Context ObjectTypeItem.
 *
 * @author Aravindh Nagarajan, Alex Klyuev
 */
export class VRFContext extends withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    private readonly auth: Auth;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'vrfcontext',
            objectType: VrfContext,
            permissionName: AviPermissionResource.PERMISSION_VRFCONTEXT,
            restrictEditOnEssentialLicense: false,
            windowElement: 'lazy-load',
        };

        super(extendedArgs);

        this.auth = this.getAjsDependency_(AUTH_SERVICE_TOKEN);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public dataToSave(): IVrfContext {
        const config: IVrfContext = super.dataToSave();
        const { bgp_profile: bgpProfile } = config;

        if (bgpProfile && isUndefined(bgpProfile.local_as)) {
            delete config.bgp_profile;
        }

        return config;
    }

    /** @override */
    public beforeEdit(): void {
        if (!this.bfdProfile) {
            this.safeSetNewChildByField(
                'bfd_profile',
                {},
            );
        }
    }

    /**
     * Returns true if VRF Context is editable, false otherwise (for
     * global and management VRFs).
     */
    public isVrfContextEditable(): boolean {
        return !this.isProtected() && this.auth.hasTenantVrf && super.isEditable();
    }

    /**
     * Getter for BFD Profile.
     */
    public get bfdProfile(): MessageItem<IBfdProfile> {
        return this.config.bfd_profile;
    }

    /**
     * Getter for Static Routes.
     */
    public get staticRoutes(): RepeatedMessageItem<StaticRouteConfigItem> {
        return this.config.static_routes;
    }

    /**
     * Add Static Route Message Item.
     */
    public addStaticRoute(): void {
        if (this.staticRoutes.isEmpty()) {
            this.staticRoutes.add();
            this.staticRoutes.at(0).routeId = '1';
        } else {
            let maxRouteId = 0;

            this.staticRoutes.config.forEach(route => {
                const routeId = Number(route.routeId);

                if (routeId > maxRouteId) {
                    maxRouteId = routeId;
                }
            });

            this.staticRoutes.add();

            const { count } = this.staticRoutes;

            this.staticRoutes.at(count - 1).routeId = (maxRouteId + 1).toString();
        }
    }

    /**
     * Delete a Static Route Message Item.
     */
    public deleteStaticRoute(staticRoute: StaticRouteConfigItem): void {
        this.staticRoutes.removeByMessageItem(staticRoute);
    }

    /**
     * Getter for BGP Profile.
     */
    public get bgpProfile(): BgpProfileConfigItem {
        return this.config.bgp_profile;
    }

    /**
     * Create Bgp Profile Message Item.
     */
    public setBgpProfile(): void {
        this.setNewChildByField('bgp_profile');
    }

    /**
     * Remove Bgp Profile.
     */
    public removeBgpProfile(): void {
        delete this.config.bgp_profile;
    }

    /**
     * Getter for Gateway Monitor Repeated Message Item.
     */
    public get gatewayMonitors(): RepeatedMessageItem<GatewayMonitorConfigItem> {
        return this.config.gateway_mon;
    }

    /**
     * Add a Gateway Monitor Message Item.
     */
    public addGatewayMonitor(): void {
        this.gatewayMonitors.add();
    }

    /**
     * Delete a Gateway Monitor Message Item.
     */
    public deleteGatewayMonitor(gatewayMonitor: GatewayMonitorConfigItem): void {
        this.gatewayMonitors.removeByMessageItem(gatewayMonitor);
    }

    /**
     * Getter for cloud uuid.
     */
    public get cloudId(): string {
        const { cloud_ref: cloudRef } = this.config;

        return this.stringService.slug(cloudRef);
    }

    /**
     * Method used to get Modal for lazy loaded component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            VrfContextModalComponent,
        } = await import(
            /* webpackChunkName: "vrf-context-modal" */
            'ng/lazy-loaded-components/modals/vrf-context-modal/vrf-context-modal.component'
        );

        return VrfContextModalComponent as Type<Component>;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.vrfContextModalBreadcrumbTitle);
    }
}

VRFContext.ajsDependencies = [
    AUTH_SERVICE_TOKEN,
    'l10nService',
];
