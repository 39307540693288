/**
 * Module for Templates>Security.
 * @preferred
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    AuthProfileCollection,
    AuthProfileItem,
    AUTH_PROFILE_COLLECTION_TOKEN,
    Certificate,
    CertificateCollection,
    CertificateManagement,
    CertificateManagementCollection,
    certificateManagementExpanderOptions,
    CERTIFICATE_COLLECTION_TOKEN,
    CERTIFICATE_ITEM_TOKEN,
    CERTIFICATE_MANAGEMENT_COLLECTION_TOKEN,
    CERTIFICATE_MANAGEMENT_ITEM_TOKEN,
    CRLConfigItem,
    CRL_CONFIG_ITEM_TOKEN,
    CustomParamsConfigItem,
    HardwareSecurityModuleConfigItem,
    HSMAwsCloudHsmConfigItem,
    HSMGroup,
    HSMGroupCollection,
    HSMSafenetClientInfoConfigItem,
    HSMSafenetLunaConfigItem,
    HSMSafenetLunaServerConfigItem,
    JWTServerProfile,
    JWTServerProfileCollection,
    jwtServerProfileToken,
    PingAccessAgentProfile,
    PingAccessAgentProfileCollection,
    PKIProfile,
    PKIProfileCollection,
    PKI_PROFILE_COLLECTION_TOKEN,
    PKI_PROFILE_ITEM_TOKEN,
    SamlServiceProviderSettingsConfigItem,
    SamlSettingsConfigItem,
    SSLCertificateConfigItem,
    SSLKeyParamsConfigItem,
    SSLProfile,
    SSLProfileCollection,
    SSLVersionConfigItem,
    SSL_CERTIFICATE_CONFIG_ITEM_TOKEN,
    SSL_KEY_PARAMS_CONFIG_ITEM_TOKEN,
    SSL_PROFILE_COLLECTION_TOKEN,
    SSL_PROFILE_ITEM_TOKEN,
    SSL_VERSION_CONFIG_ITEM_TOKEN,
} from '.';

import {
    AUTH_PROFILE_ITEM_TOKEN,
    CUSTOM_PARAMS_CONFIG_ITEM_TOKEN,
    HARDWARE_SECURITY_MODULE_CONFIG_ITEM_TOKEN,
    HSM_AWS_CLOUD_HSM_CONFIG_ITEM_TOKEN,
    HSM_GROUP_COLLECTION_TOKEN,
    HSM_GROUP_ITEM_TOKEN,
    HSM_SAFENET_CLIENT_INFO_CONFIG_ITEM_TOKEN,
    HSM_SAFENET_LUNA_CONFIG_ITEM_TOKEN,
    HSM_SAFENET_LUNA_SERVER_CONFIG_ITEM_TOKEN,
    JWT_SERVER_PROFILE_COLLECTION_TOKEN,
    PING_ACCESS_AGENT_PROFILE_COLLECTION_TOKEN,
    PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN,
    SAML_SERVICE_PROVIDER_SETTINGS_CONFIG_ITEM_TOKEN,
    SAML_SETTINGS_CONFIG_ITEM_TOKEN,
} from './security.tokens';

const securityModule = angular.module('avi/security');

const components = [{
    name: 'certificateManagementExpander',
    options: certificateManagementExpanderOptions,
}];

components.forEach(({ name, options }) => securityModule.component(name, options));

const factories = [
    {
        factory: JWTServerProfileCollection,
        name: JWT_SERVER_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: JWTServerProfile,
        name: jwtServerProfileToken,
    },
    {
        factory: PingAccessAgentProfileCollection,
        name: PING_ACCESS_AGENT_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: PingAccessAgentProfile,
        name: PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN,
    },
    {
        factory: SSLProfile,
        name: SSL_PROFILE_ITEM_TOKEN,
    },
    {
        factory: SSLProfileCollection,
        name: SSL_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: PKIProfile,
        name: PKI_PROFILE_ITEM_TOKEN,
    },
    {
        factory: SSLCertificateConfigItem,
        name: SSL_CERTIFICATE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: CRLConfigItem,
        name: CRL_CONFIG_ITEM_TOKEN,
    },
    {
        factory: PKIProfileCollection,
        name: PKI_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: CertificateManagementCollection,
        name: CERTIFICATE_MANAGEMENT_COLLECTION_TOKEN,
    },
    {
        factory: CertificateManagement,
        name: CERTIFICATE_MANAGEMENT_ITEM_TOKEN,
    },
    {
        factory: CertificateCollection,
        name: CERTIFICATE_COLLECTION_TOKEN,
    },
    {
        factory: Certificate,
        name: CERTIFICATE_ITEM_TOKEN,
    },
    {
        factory: SSLVersionConfigItem,
        name: SSL_VERSION_CONFIG_ITEM_TOKEN,
    },
    {
        factory: SSLKeyParamsConfigItem,
        name: SSL_KEY_PARAMS_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMGroupCollection,
        name: HSM_GROUP_COLLECTION_TOKEN,
    },
    {
        factory: HSMGroup,
        name: HSM_GROUP_ITEM_TOKEN,
    },
    {
        factory: HardwareSecurityModuleConfigItem,
        name: HARDWARE_SECURITY_MODULE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMSafenetLunaConfigItem,
        name: HSM_SAFENET_LUNA_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMSafenetLunaServerConfigItem,
        name: HSM_SAFENET_LUNA_SERVER_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMSafenetClientInfoConfigItem,
        name: HSM_SAFENET_CLIENT_INFO_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMAwsCloudHsmConfigItem,
        name: HSM_AWS_CLOUD_HSM_CONFIG_ITEM_TOKEN,
    },
    {
        factory: CustomParamsConfigItem,
        name: CUSTOM_PARAMS_CONFIG_ITEM_TOKEN,
    },
    {
        factory: AuthProfileCollection,
        name: AUTH_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: AuthProfileItem,
        name: AUTH_PROFILE_ITEM_TOKEN,
    },
    {
        factory: SamlServiceProviderSettingsConfigItem,
        name: SAML_SERVICE_PROVIDER_SETTINGS_CONFIG_ITEM_TOKEN,
    },
    {
        factory: SamlSettingsConfigItem,
        name: SAML_SETTINGS_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(securityModule, 'factory', name, factory);
});
